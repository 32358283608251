export class FilterSerch {
  categories = [];
  cluster = [];
  localisation = [];
  countries = [];
  categoriesConf = [];
  placesConf = [];
  themeId = null;
  isNewExhi = false;
  isLab = false;
}
