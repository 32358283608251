// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const api = "https://finderr.central-ws.com/" 
export const environment = {
  production: false,
  defaultPortal: api + "Portals/0/",
  jsonSecure:{
    getJson: "http://finderr-pwa.la-projets.com/api/HelperCtrl/GetJsonByName",
  },

  login:{
    userLogin: api + "desktopModules/FinderrApi/API/Visitor/ConnectUser",
  },
  catalogue:{
    listeExposants: api + "DesktopModules/FinderrApi/API/Exhibitor/GetAllExhibitors",
    exposantDetail: api + "desktopModules/FinderrApi/API/Exhibitor/GetExhibitor/",
    generalInfos: api + "DesktopModules/FinderrApi/API/Info/GeneralInfo"
  },

  conference:{
    listEvenement: api + "/DesktopModules/FinderrApi/API/Event/GetAllEvents",
    evenementDetail: api + "/DesktopModules/FinderrApi/API/Event/GetEvent/"
  },

  favoris: {
    setFavori: api + 'DesktopModules/FinderrApi/API/Visitor/SetFavorite/',
    setAlert: api + 'DesktopModules/FinderrApi/API/Visitor/SetAlert/',
    sendMailFavs: api + 'DesktopModules/FinderrApi/API/Visitor/SendFavoritesByMail/'
  },

  linkedIn: {
    postExhibitor: api + 'DesktopModules/FinderrApi/API/Visitor/GenerateLKPost_Exhibitor'
  },

  tracking: {
    traceClick: api + 'DesktopModules/FinderrApi/API/Visitor/TraceClick',
    traceSearch: api + 'DesktopModules/FinderrApi/API/Visitor/TraceSearch',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
