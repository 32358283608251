import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import * as _ from 'lodash';
import { DataToolsService } from 'src/app/services/data-tools.service';
@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  dataUser: any = JSON.parse(localStorage.getItem('DATA-USER'));
  text: any = JSON.parse(localStorage.getItem('TEXTJSON'));
  constructor(
    private route: Router,
    private dataToolsService: DataToolsService,
    private popCtrl: PopoverController,
  ) { }

  ngOnInit() {
    console.log("t-est--", this.text)
    let popoversTemp = _.cloneDeep(this.text.popoverSettings);
    
     //Si user n'est pas connecté on affiche pas le logout
     if(!this.dataUser){
      popoversTemp = _.filter(popoversTemp, (item) => { return item.id == 3 || item.id == 4});
      console.log("text ==> ", this.text.popoverSettings);
      this.text.popoverSettings = popoversTemp;
    }

    if(this.dataUser){
      popoversTemp = _.filter(popoversTemp, (item) => { return item.id == 1 || item.id == 3 || item.id == 0});
      console.log("text ==> ", this.text.popoverSettings);
      this.text.popoverSettings = popoversTemp;
     
    }
    
  }

   //Logout Fonction
   logout() {
    this.dataToolsService.logout();
  }

  onClickSetting(id){
    if(id == 1) this.route.navigate(['home'], { queryParams: { param: 'account' } });
    if(id == 0) this.logout();
    if(id == 3) this.dataToolsService.presentLangue();
    if(id == 4) this.route.navigate(['home'], { queryParams: { param: 'login' } });
    this.popCtrl.dismiss()
  }

}
