import { Component, HostListener } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Platform } from '@ionic/angular';
//import { initializeApp } from "firebase/app";
//import { NgxSpinnerService } from "ngx-spinner";
import { DataToolsService } from './services/data-tools.service';
//import { SplashScreen } from '';
//import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
//import { ScreensizeService } from './services/screensize.service';
import * as _ from 'lodash';
import { FilterSerch } from './models/filter-serch';
//import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
//import { getMessaging, getToken, onMessage } from "firebase/messaging";
//import { TopicService } from './services/topic.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  urlParam: string;
  show = true;
  text: any;
  itemSearch: any;
  listAllCat: any;
  generalInfos: any;
  title: string;
  searchTerm: string;
  titleParent: string;
  lastChild: any;
  lastChildIni: any;
  showSearch: boolean = false;
  isDesktop: boolean = false;
  hideInDesktopTemp: boolean = false;

  filter: FilterSerch = JSON.parse(sessionStorage.getItem('Filters'));
  isBookmarksMode = localStorage.getItem('isBookmarksMode');

  constructor(
    private platform: Platform,
    //private splashScreen: SplashScreen,
    //private statusBar: StatusBar,
    // private screensizeService: ScreensizeService,
    // private spinner: NgxSpinnerService,
    private dataToolsService: DataToolsService,
    private router: Router,
    // private fcm: FCM,
    // private topicService: TopicService
  ) {
    this.initializeApp();
    // this.router.events.subscribe((event: RouterEvent) => {
    //   this.navigationInterceptor(event)
    // })
    /** spinner starts on init */
    //  this.spinner.show();

    //  setTimeout(() => {
    //    /** spinner ends after 5 seconds */
    //    this.spinner.hide();
    //  }, 5000);
    this.getNavigatorLanguage();
    this.getTextFinderJson();
    this.getGeneralInfos();
    this.getPageContents();

    this.filter = JSON.parse(sessionStorage.getItem("Filters"));
    if (!this.filter) {
      this.filter = new FilterSerch();
      sessionStorage.setItem("Filters", JSON.stringify(this.filter));
    }
  }

  getIfIsDesktop() {
    // this.screensizeService.isDesktopView().subscribe(isDesktop => {
    //   if (this.isDesktop && !isDesktop) {
    //     // Reload because our routing is out of place
    //     window.location.reload();
    //   }

    //   this.isDesktop = isDesktop;
    //   this.hideInDesktopTemp = this.isDesktop;
    //   console.log("'''''''''''''''''''''''''''''''''' isDesktop ==> ", this.isDesktop);
    //   console.log("'''''''''''''''''''''''''''''''''' hideInDesktopTemp ==> ", this.hideInDesktopTemp);
    //   //localStorage.setItem('isDesktop', (isDesktop) ? "true" : "false");
    //   //localStorage.setItem('hideInDesktopTemp', (this.hideInDesktopTemp) ? "true" : "false");
    // });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      //this.splashScreen.hide();
      //this.screensizeService.onResize(this.platform.width());
      //this.getIfIsDesktop(); 
      //const app = this.initFirebase();
      //const messaging = getMessaging(app);
      // Initialisation de la réception des notifications
      // getToken(messaging, { vapidKey: 'BEqfpjNYiQBNqN3D84EqsMw6fDTpyNldR-VP7P-0J94DNl62L97l29TaHG8cdk3-l07dXaHGAqHiucEVulWgzOI' }).then((currentToken) => {
      //    console.log('currentToken------',currentToken)
      //   if (currentToken) {
      //     localStorage.setItem('TOKEN-NOT', currentToken);
      //     console.log('OK FCM');
      //     this.topicService.subscribeTokenToTopic(currentToken,"test");
      //     onMessage(messaging, (payload) => {
      //       console.log('Message received. ', payload);
      //       // ...
      //     });
      //   } else {
      //     console.warn('No registration token available. Request permission to generate one.');
      //   }
      // }).catch((err) => {
      //   console.error('An error occurred while retrieving token. ', err);
      // });
      // onMessage(messaging, (payload) => {
      //   console.log('Message received. ', payload);
      // });
    });
  }

  initFirebase() {
    const firebaseConfig = {
      apiKey: "AIzaSyDmpf_70BhBQL6dV187kpXpZLPagSb7ys8",
      authDomain: "finderr-64db8.firebaseapp.com",
      projectId: "finderr-64db8",
      storageBucket: "finderr-64db8.appspot.com",
      messagingSenderId: "960517462274",
      appId: "1:960517462274:web:ba1befbf22f291f4940a1b",
      measurementId: "G-SWJ3NHEN5J"
    };
    // const app = initializeApp(firebaseConfig);
    //  return app;
  }


  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    // this.screensizeService.onResize(event.target.innerWidth);
  }

  // Shows and hides the loading spinner during RouterEvent changes
  // navigationInterceptor(event: RouterEvent): void {
  //   if (event instanceof NavigationStart) {
  //     //console.log('START--------------');
  //     this.spinner.show();
  //   }
  //   if (event instanceof NavigationEnd) {
  //     //console.log('END--------------');
  //     this.spinner.hide();
  //   }

  //   // Set loading state to false in both of the below events to hide the spinner in case a request fails
  //   if (event instanceof NavigationCancel) {
  //     this.spinner.hide();
  //   }
  //   if (event instanceof NavigationError) {
  //     this.spinner.hide();
  //   }
  // }

  //Détecté la langue du navigateur
  getNavigatorLanguage() {
    if (localStorage.getItem("langue")) {
      return;
    }
    let lang = navigator.language;
    let langue = lang.substring(0, 2);
    console.log("lang---->", langue);
    localStorage.setItem('langue', langue);
  }

  //Récupération des textes de l'APP
  async getTextFinderJson() {
    //this.dataToolsService.getJsonSecure('text-finder').subscribe(data => {
    //  console.log("textes===>", data)
    //  this.text = data;
    //  localStorage.setItem('TEXTJSON', JSON.stringify(this.text));
    //})

    this.text = await this.dataToolsService.getJsonSecureAsync('text-finder');
    console.log("TEXTJSON:", this.text);
    
    localStorage.setItem('TEXTJSON', JSON.stringify(this.text));
    return this.text;
  }

  //Récupérer les infos géneral
  async getGeneralInfos() {
    //this.dataToolsService.getJsonSecure('GeneralInfo').subscribe((data) => {
    //  console.log("datas===>", data);
    //  this.generalInfos = data;
    //  sessionStorage.setItem("GENERAL-INFOS", JSON.stringify(this.generalInfos));
    //})

    this.generalInfos = await this.dataToolsService.getJsonSecureAsync('GeneralInfo');
    console.log("GENERAL-INFOS:", this.generalInfos);

    sessionStorage.setItem("GENERAL-INFOS", JSON.stringify(this.generalInfos));
  }

  //Récuperer page contents
  async getPageContents() {
    //this.dataToolsService.getJsonSecure('PageContents').subscribe(data => {
    //  console.log("pageContens--------->", data);
    //  sessionStorage.setItem("PAGE-CONTENT", JSON.stringify(data));
    //})

    let pageContents = await this.dataToolsService.getJsonSecureAsync('PageContents');
    console.log("PAGE-CONTENT:", pageContents);

    sessionStorage.setItem("PAGE-CONTENT", JSON.stringify(pageContents));
  }

  getListName(title) {
    //console.log("title ==> ", title);
    //console.log("this.filter---", this.filter);
    let arrName = title;
    switch (title) {
      case "EventCategories":
        arrName = "categoriesConf";
        break;
      case "EventPlaces":
        arrName = "placesConf";
        break;
      case "places":
        arrName = "localisation";
        break;
      case "clusters":
        arrName = "cluster";
        break;
      default:
        arrName = title;
        break;
    }
    return arrName;
  }

  getLengthNb(title) {
    //console.log("this.filter---", this.filter);
    //if (title) console.log("title---", title);

    let listName = this.getListName(title);
    //console.log("listName ==> ", listName);

    if (!this.filter[listName] || this.filter[listName].length < 1) return;

    let nbLength = this.filter[listName].length;
    return nbLength;
  }

  getLengthChildNb(title) {
    //console.log("this.filter---", this.filter);
    if (title) console.log("title fddsqdfqdsf---", title);

    let isInSubCat = [];
    let listName = this.getListName(title);

    if (!this.filter[listName] || this.filter[listName].length < 1) return;

    this.filter[listName].forEach((itemId) => {
      console.log("itemId ==> ", itemId);
      let currentItem = _.filter(this.listAllCat, function (cat) { return cat.Cat_Id == itemId })[0];
      if (currentItem) {
        console.log("currentItem ==> ", currentItem);
        if (this.itemSearch.length > 0)
          isInSubCat = _.filter(this.listAllCat, function (cat) { return cat.Cat_External_Id.includes(currentItem.Cat_External_Id.charAt(0)) && cat.Cat_Id != currentItem.Cat_Id });
      }
    });

    console.log("isInSubCat ==> ", isInSubCat);

    //if (isInSubCat.length < 1) return;

    //let nbLength = isInSubCat.length;
    //return nbLength;

    return true;
  }

  getIfSelected(_title) {
    let nb = this.getLengthNb(_title);
    //console.log("number ==> ", nb)
    let isSelected: boolean = (nb && nb > 0) ? true : false;
    return isSelected;
  }


  //Récupérer les item de la recherche
  getAll(itemId, title) {
    //console.log("itemId---", itemId);
    //console.log("title---", title);

    this.title = (title == 'placesConf') ? 'places' : (title == 'categoriesConf') ? 'categories' : title;

    this.titleParent = title;
    this.listAllCat = this.generalInfos[itemId];

    console.log("this.listAllCat ==> ", this.listAllCat);

    //--------Si c'est categories----------//
    if (itemId == "ExhibitorCategories")
      this.itemSearch = _.filter(this.listAllCat, function (cat) { return cat.Cat_External_Id.length == 1 });

    //--------Si c'est cluster----------//
    if (itemId == "Clusters") this.lastChild = this.listAllCat;
    //this.itemSearch = _.filter(this.listAllCat, function(cat) {return cat.Cluster_External_Id.length == 1});

    //--------Si c'est place----------//
    if (itemId == "Places") this.lastChild = _.filter(this.listAllCat, function (cat) { return cat.Place_Type == 'EXHIBITOR' });

    //--------Si c'est countries----------//
    if (itemId == "Countries") {
      this.lastChildIni = this.listAllCat;

      this.lastChildIni = _.orderBy(this.lastChildIni, ['Country_Name'], ['asc']);
      this.lastChild = _.cloneDeep(this.lastChildIni);
    }

    // console.log("showSearch---", this.showSearch)

    //--------Si c'est EventCategories-----//
    if (itemId == "EventCategories") this.lastChild = this.listAllCat;

    //--------Si c'est EventPlaces-----//
    if (itemId == "EventPlaces") {
      this.listAllCat = this.generalInfos['Places'];
      this.lastChild = _.filter(this.listAllCat, function (cat) { return cat.Place_Type == 'CONFERENCE' });
    }

    console.log("this.lastChild ==> ", this.lastChild);
    console.log("itemSearch ==> ", this.itemSearch);
  }

  //Retour à l'ecran pricipal de la recherche
  back() {
    if (this.lastChild && this.itemSearch) { this.lastChild = null; this.itemSearch = null }
    if (this.lastChild) { this.lastChild = null; return }
    if (!this.lastChild || this.itemSearch) { this.itemSearch = null; }
  }

  //Remplacer le titre par le nom de la cat
  goToChild(item, catName) {
    //console.log("itemSearch--------", this.itemSearch)
    //console.log("catName--------", catName);
    //console.log("title----------", this.title);
    this.title = item[catName];

    //--------Si c'est categories----------//
    if (catName == "Cat_Name")
      this.lastChild = _.filter(this.listAllCat, function (cat) { return cat.Cat_External_Id.includes(item.Cat_External_Id.charAt(0)) });
    _.remove(this.lastChild, function (cat) { return cat.Cat_External_Id.length == 1 });

    //--------Si c'est cluster----------//
    // if(catName == "Cluster_Name")
    // this.lastChild = _.filter(this.listAllCat, function(cat) {return cat.Cluster_External_Id.includes(item.Cluster_External_Id.charAt(0))});

    //console.log("CHILD-----", this.lastChild);
  }

  //Fonction pour afficher le name
  getKeyName(titleParent) {

    let keyName = '';

    switch (titleParent) {
      case "categories":
        keyName = "Cat_Name"
        break;
      case "clusters":
        keyName = "Cluster_Name"
        break;
      case "places":
        keyName = "Place_Name"
        break;
      case "countries":
        keyName = "Country_Name"
        break;
      case "categoriesConf":
        keyName = "CatEvent_Name"
        break;
      case "placesConf":
        keyName = "Place_Name"
        break;
      case "placesConf":
        keyName = "Place_Name"
        break;
      default:
        break;
    }
    return keyName;
  }
  //Fonction pour afficher le name
  getKeyId(titleParent) {

    let exteralId = '';

    switch (titleParent) {
      case "categories":
        exteralId = "Cat_Id"
        break;
      case "clusters":
        exteralId = "Cluster_External_Id"
        break;
      case "places":
        exteralId = "Place_Id"
        break;
      case "countries":
        exteralId = "Country_Id"
        break;
      case "categoriesConf":
        exteralId = "CatEvent_External_Id"
        break;
      case "placesConf":
        exteralId = "Place_Id"
        break;
      default:
        break;
    }
    return exteralId;
  }

  onChange(event, item) {
    console.log("item----------->", item);
    console.log("event.target.checked----------->", event.target.checked);
    console.log("event----------->", event);

    let checked = event.target.checked;
    let id = event.target.value;

    // switch (item) {
    //   case "Cat_Name":
    //     if (checked) this.filter.categories.push(id);
    //     else _.remove(this.filter.categories, function (cat) { return cat == id });
    //     break;
    //   case "Cluster_Name":
    //     if (checked) this.filter.cluster.push(id);
    //     else _.remove(this.filter.cluster, function (clu) { return clu == id });
    //     break;
    //   case "Place_Name":
    //     let itemInList = _.filter(this.lastChild, function (itm) { return itm.Place_Id == id })[0];
    //     if (!itemInList) return;
    //     let arrPlacesToFilter = (itemInList.Place_Type == 'EXHIBITOR') ? 'localisation' : 'placesConf';

    //     if (checked) this.filter[arrPlacesToFilter].push(id);
    //     else _.remove(this.filter[arrPlacesToFilter], function (loc) { return loc == id });
    //     break;
    //   case "Country_Name":
    //     if (checked) this.filter.countries.push(id);
    //     else _.remove(this.filter.countries, function (con) { return con == id });
    //     break;
    //   case "CatEvent_Name":
    //     if (checked) this.filter.categoriesConf.push(id);
    //     else _.remove(this.filter.categoriesConf, function (conf) { return conf == id });
    //     break;
    //   default:
    //     break;
    // }

    console.log("this.filter ================================> ", this.filter);
  }

  isItemInList(_item, _titleparent) {
    //console.log(_item, _titleparent);
    let listName = this.getListName(_titleparent);
    let val = _item[this.getKeyId(_titleparent)];
    let isInList: boolean = (this.filter[listName] && this.filter[listName].indexOf(val) != -1) ? true : false;
    return isInList;
  }

  onChangeExiNew(_event) {
    // this.filter.isNewExhi = _event.target.checked;
  }

  //Sauvegarder l'objet filtre dans le session storage
  saveFilter() {
    sessionStorage.setItem("Filters", JSON.stringify(this.filter));
    this.back();
  }

  searchAll() {
    // this.spinner.show();
    let pathName = this.router.url;
    console.log("PATHNAME--", pathName);
    localStorage.setItem('isBookmarksMode', 'No');
    sessionStorage.setItem("Filters", JSON.stringify(this.filter));
    this.closeSearch();

    setTimeout(() => {
      if (pathName.includes('sommaire') || pathName.includes('solutions')) {
        console.log("yessss")
        this.router.navigate(['home'], { queryParams: { param: 'exhibitors'} });
        return;
      }
      if (pathName.includes('programme') || pathName.includes('speakers')) {
        this.router.navigate(['home'], { queryParams: { param: 'conferences'} });
        return;
      }

      location.reload();
    }, 400);
  }

  //Search countries
  onSearch(e) {
    console.log("eve======", e.target.value, this.lastChild);

    if (!e.target.value || e.target.value == "") {
      this.lastChild = _.cloneDeep(this.lastChildIni);
      return;
    }

    let arr = []
    let re = new RegExp('^' + e.target.value, "gi");
    this.lastChild = _.filter(this.lastChildIni, function (item) {
      //console.log( item.Country_Name);
      if (re.test(item.Country_Name)) {
        arr.push(item)
      }
      return re.test(item.Country_Name);
    });
    this.lastChild = arr;
    console.log(this.lastChild, arr);
  }

  //colse search
  closeSearch() {
    this.dataToolsService.close();
  }

  initialize() {
    this.filter = new FilterSerch();
    sessionStorage.setItem("Filters", JSON.stringify(this.filter));
  }
}
