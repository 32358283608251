import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common'; 
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { GlobalSearchPipe } from './pipes/global-search.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MenuConfComponent } from './components/menu-conf/menu-conf.component';
import { MapComponent } from './components/map/map.component';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { PopoverComponent } from './components/popover/popover.component';
import { InterceptorService } from './services/interceptor.service';
@NgModule({
  declarations: [AppComponent, GlobalSearchPipe, PopoverComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    CommonModule,
    HttpClientModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    CookieService,
    GlobalSearchPipe,
    Ng2SearchPipeModule,
    MapComponent,
    NgxSpinnerService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
