import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, MenuController, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { FilterSerch } from '../models/filter-serch';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import * as md5 from 'md5';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MapComponent } from '../components/map/map.component';

@Injectable({
  providedIn: 'root'
})
export class DataToolsService {
  loginUrl = environment.login.userLogin;
  getJsonUrl = environment.jsonSecure.getJson;
  favorisUrl = environment.favoris.setFavori;
  alertFavUrl = environment.favoris.setAlert;
  sendMailFavsUrl = environment.favoris.sendMailFavs;
  postExhibitorUrl = environment.linkedIn.postExhibitor;
  traceClickUrl = environment.tracking.traceClick;
  traceSearchUrl = environment.tracking.traceSearch;
  text: any = JSON.parse(localStorage.getItem('TEXTJSON'));
  filter: FilterSerch = JSON.parse(sessionStorage.getItem("Filters"));
  messageFav: string;
  messageAlert: string;
  langue: string = localStorage.getItem("langue");
  constructor(
    private http: HttpClient,
    private menu: MenuController,
    private router: Router,
    private alertController: AlertController,
    private cookieService: CookieService,
    private toastController: ToastController,
    // private topicService: TopicService,
    private popCtrl: PopoverController,

    // private map: MapComponent
  ) { }

  //Fonction LOGOUT
  logout() {
    localStorage.removeItem("DATA-USER");
    localStorage.setItem("isBookmarksMode", "No");
    //this.filter = new FilterSerch();
    //sessionStorage.setItem('Filters', JSON.stringify(this.filter));
    this.popCtrl.dismiss();
    this.presentAlertLogoutSuccess(this.text.logoutTxt.success);
  }

  styleScrollbars(elmt: any) {
    const stylesheet = `
      ::-webkit-scrollbar {
        width: 15px;
      }
      ::-webkit-scrollbar-track {
        background: #15154A;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: #85EFFD;
        border: 5px solid #15154A;
      }
      ::-webkit-scrollbar-thumb:hover {
      }
    `;

    const styleElmt = elmt.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      elmt.shadowRoot.appendChild(barStyle);
    }
  }

  //==============TRACKING
  traceClick(data) {
    let params = new HttpParams();
    params = params.append('userID', data.userId);
    params = params.append('itemtype', data.itemType);
    params = params.append('itemID', data.itemId);

    return this.http.post(this.traceClickUrl, params.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  doTraceClick(data) {
    this.traceClick(data).subscribe(res => {
      console.info("traceClick => res", res);
    });
  }

  traceSearch(data) {
    let params = new HttpParams();
    params = params.append('userID', data.userId);
    params = params.append('searchtype', data.searchType);
    params = params.append('keywords', data.keywords);

    return this.http.post(this.traceSearchUrl, params.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  //==============LINKEDIN
  makeLinkedInPost(data, type) {
    let params = new HttpParams();
    params = params.append('userID', data.userId);
    params = params.append('language', data.lang);

    if (type == "exhi") params = params.append('exhiID', data.itemId);

    return this.http.post(this.postExhibitorUrl, params.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  //==============SEND FAVORIS
  sendMailFavs(usrId) {
    return this.http.get(this.sendMailFavsUrl + usrId.toString());
  }


  //Go to conference detail
  goToConfDetail(Event_Id) {
    //this.router.navigate(['detail-conference/' + Event_Id]);
    this.router.navigate(['home'], { queryParams: { param: 'detail-conference', id: Event_Id } })
  }

  //Fonction redirection vers la carte
  goToMap(mapPoint) {
    this.router.navigate(['home'], { queryParams: { param: 'mapPoint', idMap: mapPoint } });
    //this.map.ngOnInit();
  }

  onClickImgBottomMap(_exi) {
    if (!_exi.Stands) return;
    this.goToMap(_exi.Stands[0].MapPoint);
  }

  //Fonction close menu Search
  close() {
    //this.menu.close();

  }

  //Open menu search
  open() {
    //this.menu.open();

  }


  //Cryptage de fichiers JSON
  cryptFile() {
    let crypt = md5('hacene');
    console.log("------", crypt)
  }

  //Get json sécurisé
  getJsonSecure(nameJson: string) {
    let langue = localStorage.getItem("langue");
    let jsonPath = "";

    if (langue == "fr") {
      jsonPath = jsonPath + "/fr/"
    } else {
      jsonPath = jsonPath + "/en/"
    }
    if (nameJson == "imgThemes") {
      jsonPath = "/";
    }
    jsonPath = jsonPath + nameJson + ".json";

    let _params = new HttpParams();
    _params = _params.append('JsonName', jsonPath);
    _params = _params.append('JsonKey', md5(jsonPath));

    return this.http.get(this.getJsonUrl, { params: _params });
  }

  getJsonSecureAsync(nameJson: string) {
    let langue = localStorage.getItem("langue");
    let jsonPath = "";

    if (langue == "fr") {
      jsonPath = jsonPath + "/fr/"
    } else {
      jsonPath = jsonPath + "/en/"
    }
    if (nameJson == "imgThemes") {
      jsonPath = "/";
    }
    jsonPath = jsonPath + nameJson + ".json";

    let _params = new HttpParams();
    _params = _params.append('JsonName', jsonPath);
    _params = _params.append('JsonKey', md5(jsonPath));

    return this.http.get(this.getJsonUrl, { params: _params }).toPromise();
  }

  //Récuperation data json
  getJSON(nameJson: string): Observable<any> {
    let jsonPath = "./assets/datasJSON";
    let langue = localStorage.getItem("langue");
    if (langue == "fr") {
      jsonPath = jsonPath + "/fr/"
    } else {
      jsonPath = jsonPath + "/en/"
    }
    return this.http.get(jsonPath + nameJson + ".json");
  }

  //Récupération des JSON config
  getJSONAlt(nameJson: string): Observable<any> {
    let jsonPath = "./assets/datasJSON/";

    return this.http.get(jsonPath + nameJson + ".json");
  }

  //---------------connexion user
  login(login: string, password: string) {
    const body = new HttpParams()
      .set('login', login)
      .set('password', password);

    return this.http.post(this.loginUrl, body.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  //-----------------------Return label à partir de l'id
  getLabelById(id, idKey: string, labelKey: string, arrayDats) {
    let obj;
    obj = arrayDats.filter((item) => { return item[idKey] == id })[0];
    let labelName = (obj) ? obj[labelKey] : "";

    return labelName;
  }

  //Formater les dates et récupérer l'heure
  formatDateHour(date) {
    return moment(date).format("HH:mm");
  }

  //Formater les dates DD/MM/YYYY
  formatJMA(date) {
    return moment(date).format("DD/MM/YYYY");
  }

  //Formatter la date pour récupérer le mois et l'année
  formatDateMY(date) {
    // console.log("DAT---", date);
    let month = moment(date).month() + 1;
    let year = moment(date).year().toString();
    let yearSub = year.substring(2, 4)
    return month + "/" + yearSub;
  }

  //Formater la date en dd/mm
  formatDateDM(date) {
    return date.substring(0, 5);
  }

  //Formater la date en DD/MM/YYYY
  formatDateDMY(date) {
    return moment(date).format("DD/MM/YYYY")
  }

  formatDateDMNotString(date) {
    return moment(date).format("DD/MM")
  }

  //Récupérer les initials d'un contact
  getInitials(prenom, nom) {
    let lastName = prenom.substring(0, 1);
    let firstName = nom.substring(0, 1);
    return firstName + "" + lastName;
  }

  //Changer couleur star Fav
  onClickFav(id, type) {
    this.messageFav = '<img class="fav" src="/assets/images/pictos/favoris-on.svg" /><h1 class="custom-title">' + this.text.notifications.favTitle + '</h1><div class="custom-text">' + this.text.notifications.favMessag + '</div>';
    //let toasts = document.getElementsByClassName('toast-custom');
    //if (toasts.length > 0 && this.toastController) this.toastController.dismiss();
    //console.log("toasts ==> ", toasts);
    console.log("id ==> ", id);

    let dataUser = JSON.parse(localStorage.getItem("DATA-USER"));
    if (dataUser) {
      this.setFavoris(dataUser.UserID, type, id).subscribe(res => {
        //console.log("res ==> ", res);
        if (!this.itemExist(id, type)) {
          let objToPush = {
            Type: type,
            ID: id
          }
          dataUser.Favorites.push(objToPush);
          localStorage.setItem("DATA-USER", JSON.stringify(dataUser));
          console.log("dataUser.Favorites-----", dataUser.Favorites);
          this.presentToast(this.messageFav, 35);
        }
        else {
          let index = _.findIndex(dataUser.Favorites, function (item) { return item.ID == id && item.Type == type });
          console.log('//////////', index)
          dataUser.Favorites.splice(index, 1);
          console.log('//////////', dataUser.Favorites)
          localStorage.setItem("DATA-USER", JSON.stringify(dataUser));
        }
      }, (err) => {
        console.log('ERR----->', err);
      });
    }
    else {
      this.presentAlertUserNotLogged();
    }
  }

 // ==> ION TOGGLE Check is alert ou pas
 onCheckedAlertBis(id, type, item) {
  this.text = JSON.parse(localStorage.getItem('TEXTJSON'));
  console.log("id ==> ", id);
  let toasts = document.getElementsByClassName('toast-custom');
  if (toasts.length > 0 && this.toastController) this.toastController.dismiss();
  this.messageAlert = '<img class="fav" src="/assets/images/pictos/bell_white.svg" /><h1 class="custom-title-bell">' + this.text.notifications.alertTitle + '</h1><div class="custom-text">' + this.text.notifications.alertMessage + '</div>';

  let dataUser = JSON.parse(localStorage.getItem("DATA-USER"));


  if (dataUser) {
    let arrayCurrent = (type == "EVENT") ? dataUser.AlertsEvents : (type == "PROGRAM") ? dataUser.AlertsProgramItems : null;
    this.setAlert(dataUser.UserID, type, id).subscribe((res: any) => {
      console.log("res this.setAlert ==> ", res);

      if (!this.itemChecked(id, type)) {
        arrayCurrent.push(id);
        item.isAlertEvent = true;
        localStorage.setItem("DATA-USER", JSON.stringify(dataUser));
        this.presentToast(this.messageAlert, 15);
      }
      else {
        let topic: any;
        topic = (type == "EVENT") ? `event_${id}` : (type == "PROGRAM") ? `program_${id}` : "";
        console.log('topic ==> ', topic);

       // this.topicService.subscribeTokenToTopic(this.currentTokenFirebase, topic);

        let index = _.findIndex(arrayCurrent, function (_item) { return _item == id });
        console.log('index ==> ', index);
        arrayCurrent.splice(index, 1);
        item.isAlertEvent = false;
        localStorage.setItem("DATA-USER", JSON.stringify(dataUser));
      }

      //console.log("arrayCurrent ==> ", arrayCurrent);

    }, (err) => { console.log('ERR ==> ', err); });
  }
  else {
    //console.log("item.isAlertEvent ==> ", item.isAlertEvent)
    if (!item.isAlertEvent && !dataUser) {
      item.isAlertEvent = false;
      this.presentAlertUserNotLogged();
      return;
    }
  }
}


onToggleChange(toggleValue, item) {
  let dataUser = JSON.parse(localStorage.getItem("DATA-USER"));
  if (dataUser) return;

  let val;

  if (toggleValue._value == true) val = true;
  else if (toggleValue._value == false) val = false;

  item.isAlertEvent = val;
}

  //Fonction pour verifier si l'item existe dans la liste favoris
  itemChecked(id, type) {
    let dataUser = JSON.parse(localStorage.getItem("DATA-USER"));
    let obj: any = null;
    let inInList: boolean = false;

    if (dataUser) {
      if (type == "PROGRAM") {
        if (dataUser.AlertsProgramItems) {
          obj = _.filter(dataUser.AlertsProgramItems, function (item) { return item == id; })[0];
        }
      }
      if (type == "EVENT") {
        if (dataUser.AlertsEvents) {
          obj = _.filter(dataUser.AlertsEvents, function (item) { return item == id; })[0];
        }
      }
    }

    //console.log("OBJ ==> ", obj);
    inInList = (obj) ? true : false;
    //console.log("inInList ==> ", inInList);

    return inInList;
  }

  //Fonction pour verifier si l'item existe dans la liste favoris
  itemExist(id, type) {
    let dataUser = JSON.parse(localStorage.getItem("DATA-USER"));
    if (dataUser && dataUser.Favorites) {
      let obj: any;
      obj = _.filter(dataUser.Favorites, function (item) { return item.ID == id && item.Type == type })[0];
      // console.log("OBJ----------", obj);
      let inInList: boolean = (obj) ? true : false;
      return inInList;
    }

  }

  //==============SET FAVORIS
  setFavoris(usrId, type, itemId) {
    let params = new HttpParams();
    params = params.append('userID', usrId);
    params = params.append('type', type);
    params = params.append('itemId', itemId);
    return this.http.get(this.favorisUrl, { params: params });
  }

  //==============SET ALERT
  setAlert(usrId, type, itemId) {
    const body = new HttpParams()
      .set('userID', usrId)
      .set('type', type)
      .set('itemId', itemId);

    return this.http.post(this.alertFavUrl, body.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  showPopUpUserNotConnected() {
    console.log("showPopUpUserNotConnected clicked!", localStorage.getItem("DATA-USER"));
    let userLogged: any = localStorage.getItem("DATA-USER");
    let userIsConnected: boolean = (userLogged) ? true : false;
    if (!userIsConnected) {
      this.presentAlertUserNotLogged();
    }
  }

  //Alert User is not Logged
  async presentAlertUserNotLogged() {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert alert-logged',
      message: this.text.alertUserIsLogged.message,
      buttons: [
        {
          text: this.text.alertUserIsLogged.buttonAccept,
          role: 'confirm',
          cssClass: 'btn-alert btn-accept',
          id: 'login',
          handler: () => {
            console.log('Confirm Okay');
            this.router.navigate(['home'], { queryParams: { param: 'login' } });
          }
        },
        {
          text: this.text.alertUserIsLogged.buttonCancel,
          role: 'cancel',
          cssClass: 'btn-alert btn-cancel',
          id: 'not-now',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }
      ],
      backdropDismiss: false
    });

    await alert.present();
  }


  //Alert Favoris not logged
  async presentAlertFavorisNotLogged() {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert alert-logged',
      message: this.text.alertFavoris.messageFavNotLogin,
      buttons: [
        {
          text: this.text.alertUserIsLogged.buttonAccept,
          role: 'confirm',
          cssClass: 'btn-alert btn-accept',
          id: 'login',
          handler: () => {
            console.log('Confirm Okay');
            this.router.navigate(['login'], {});
          }
        },
        {
          text: this.text.alertUserIsLogged.buttonCancel,
          role: 'cancel',
          cssClass: 'btn-alert btn-cancel',
          id: 'not-now',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }
      ],
      backdropDismiss: false
    });

    await alert.present();
  }

  //Alert Cookie
  async presentAlertCookies() {
    let langue = localStorage.getItem("langue");
    const alert = await this.alertController.create({
      cssClass: 'custom-alert alert-cookies',
      // header: 'Confirm!',
      subHeader: this.text.alertCookies.textHeader,
      message: this.text.alertCookies.message,
      buttons: [
        {
          text: this.text.alertCookies.bouttonAccept,
          role: 'cancel',
          cssClass: 'btn-alert btn-accept',
          id: 'accept-cookies',
          handler: () => {
            //console.log('Confirm Cancel: blah');
            this.cookieService.set('accept-cookies', 'true', 30);
          }
        }, {
          text: this.text.alertCookies.cookiesPolicy,
          cssClass: 'btn-link',
          id: 'cookies-policy',
          handler: () => {
            console.log('Confirm Okay');
            if (langue == 'fr')
              window.open('https://www.eurosatory.com/cookies/', '_blank');
            if (langue == 'en')
              window.open('https://www.eurosatory.com/cookies/?lang=en', '_blank');
            return false;
          }
        }
      ],
      backdropDismiss: false
    });

    await alert.present();
  }

  //Toas pour Favoris
  async presentToast(message, tail) {
    // ==> S'il y a un toast présent dans l'écran, on le vire.
    let toasts = document.getElementsByClassName('toast-custom');
    if (toasts.length > 0 && this.toastController) this.toastController.dismiss();

    // ==> On crée le toast
    const toast = await this.toastController.create({
      message: message,
      cssClass: 'toast-custom',
      position: 'middle',
      duration: 1500
    });
    toast.present().then(() => {
      const toasts = document.getElementsByClassName('toast-custom');
      const shadow = toasts[0].shadowRoot;
      const childNodes = Array.from(shadow.childNodes);
      //console.log("childNodes", childNodes);
      childNodes.forEach((childNode: any) => {
        //console.log("childNode ==> ", childNode);
        const picto = childNode.getElementsByClassName('fav');
        const title = childNode.getElementsByClassName('custom-title');
        const title_bell = childNode.getElementsByClassName('custom-title-bell');
        const text = childNode.getElementsByClassName('custom-text');

        if (picto.length > 0) picto[0].setAttribute('style', 'width:' + tail + '% !important');
        if (title.length > 0) title[0].setAttribute('style', 'margin: 0;font-size: 24px;font-weight: 700;margin-top: -20px;');
        if (title_bell.length > 0) title_bell[0].setAttribute('style', 'margin: 0;font-size: 24px;font-weight: 700;margin-top: 0px;');
        if (text.length > 0) text[0].setAttribute('style', 'font-size: 16px;font-weight: 400;');
      });
    });
  }

  //Popup logout
  async presentAlertLogoutSuccess(messageHeader) {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert alert-logged',
      header: messageHeader,
      message: "",
      buttons: [
        {
          text: 'OK',
          role: 'OK',
          cssClass: 'btn-alert btn-accept',
          // id: 'accept-cookies',
          handler: () => {
            //location.reload();
            this.router.navigate(['home'], { queryParams: { param: 'login' } });
            setTimeout(() => {
              location.reload();
            }, 400);

          }
        }
      ],
      backdropDismiss: false
    });

    await alert.present();
  }
  //Popup logout
  async presentLangue() {
    const alert = await this.alertController.create({
      cssClass: 'alert-langue',
      message: this.text.homePage.language,
      buttons: [
        {
          text: '',
          role: '',
          cssClass: 'btn-img btn-img-en',
          id: '',
          handler: () => {
            console.log('Confirm Okay');
            //this.router.navigate(['login'], {});
            this.langue = 'en';
            localStorage.setItem("langue", this.langue);
            this.getJsonSecure('text-finder').subscribe(data => {
              console.log("textes===>", data)
              this.text = data;
              localStorage.setItem('TEXTJSON', JSON.stringify(this.text));
            })
            location.reload();
          }
        },
        {
          text: '',
          role: '',
          cssClass: 'btn-img btn-img-fr',
          id: '',
          handler: () => {
            console.log('Confirm Cancel');
            this.langue = 'fr';
            localStorage.setItem("langue", this.langue);
            this.getJsonSecure('text-finder').subscribe(data => {
              console.log("textes===>", data)
              this.text = data;
              localStorage.setItem('TEXTJSON', JSON.stringify(this.text));
            })
            location.reload();
          }
        }
      ],
      backdropDismiss: false
    });

    await alert.present();
  }

  goToSommaire() {
    this.router.navigate(['home']);
  }


    // Return nombre de filtre

    nbFiltre(){
      let filter = JSON.parse(sessionStorage.getItem('Filters'));
      let nbfilter: number = 0; 
      nbfilter = filter.categories.length + 
      filter.categoriesConf.length + 
      filter.cluster.length + 
      filter.countries.length + 
      filter.localisation.length +
      filter.placesConf.length;
      if(filter.isNewExhi) nbfilter = nbfilter + 1;
      if(filter.isLab) nbfilter = nbfilter + 1;
     // console.log("NB--FILTER", nbfilter);
      return nbfilter;
    }
}

