import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import VisioWebEssential from '../../../assets/map.essential.js';
import Visioweb from '../../../assets/map.js';
import * as _ from "lodash"
import { AlertController, MenuController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { DataToolsService } from '../../services/data-tools.service';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {

  @ViewChild('container') container: ElementRef;
  essential: any;
  visioweb: any;
  buildings = [];
  buildingSelected: any;
  venuLayout: any;
  floors = [];
  floorSelected: any;
  routeFromElement: string;
  routeToElement: string;
  placeTitle;
  placeIcon;
  placeDesctription;
  prevInstructionElement: string;
  nextInstructionElement: string;
  clearRouteElement: string;
  instructionIcon;
  instructionBrief;
  instructionDetail;
  updateInstructionData;
  categoriesTabNameElement: string;
  placesTabNameElement: string;
  searchInput: string;
  searchTerm: string;
  categoryPlaces;
  progress = 0;
  isModalOpen: boolean = false;
  isModalExhiOpen: boolean = false;
  locations = [];
  exhibitors: any = [];
  conferences: any = [];
  arrFilterStand: any = [];
  elementsNext= 5;
  elementFirst= 0;
  zoomCamera: number = 250;
  zooom: number = 600
  menuNavMap: any = [
    {
      id: 1,
      label: "ALL"
    },
    {
      id: "B5A",
      label: "5A"
    },
    {
      id: "B5B",
      label: "5B"
    },
    {
      id: "B6",
      label: "6"
    },
    {
      id: 5,
      label: "OUT"
    },
  ]
  constructor(
    private menu: MenuController,
    public toastController: ToastController,
    private aletrtController: AlertController,
    private activatedRoute: ActivatedRoute,
    public dataToolService: DataToolsService,
    private router: Router
  ) { }

  ngOnInit() {
     //===============Récupération des exhibitors===================//
     this.dataToolService.getJsonSecure("Exhibitors").subscribe(res => {
      //console.log(res);
      this.exhibitors = res;
    }, err => {
      console.log(err);
    });

       //==============Récupération des Conférences=================//
       this.dataToolService.getJsonSecure("Events").subscribe(res => {
        console.log(res);
        this.conferences = res;
      }, err => {
        console.log(err);
      })
 
    this.essential = new VisioWebEssential({
      element: document.getElementById('container'),
      imagePath: '../../assets'
    });
    console.log("essential--", this.essential);
    // loading a local bundle: application/data.bundles/com.visioglobe.sdk/visio.island
    this.essential.setParameters({ parameters: { baseURL: '../../../assets/visioMap', hash: '' } });

    // loading a remote bundle: com.visioglobe.sdk/visio.island hosted on mapserver.visioglobe.com
   // this.essential.setParameters({parameters: {baseURL: 'https://mapserver.visioglobe.com/', hash: 'dev-k6d0acba1949c4148f5e9cee87d29002449dd3663'}});
 
    // loading your own map using a web hash. NB: default baseURL is 'https://mapserver.visioglobe.com/' so no need to set it unless your map is hosted somewhere else
    //this.essential.setParameters({parameters: {hash: 'your_map_hash'}});
    console.log("ESSENTIAL----->", this.essential);

    this.essential.setParameters({ parameters: { locale: { language: navigator.language } } });
    this.setupProgress();
    setTimeout(() => {
      this.essential.createMapviewer()
        .then(() => {
          // logVisioWebTitle();
         // this.startCameraAnimation();
          setTimeout(() => {
            this.essential._mapviewer.getPOI('DynamicDemo')[0].options('startAnimation', 'Take 001');
          }, 2000);
          
          //Mise en surbrillance des places
          this.activatedRoute.queryParams.subscribe((params) => {
            console.log("params:", params);
           
            if (params.idMap) {
              console.log("----------------REDIRECT-----------------")
              let pointMap = params.idMap.replaceAll(' ', '');
              console.log("***MapPoint Splité***", pointMap)
                setTimeout(() => {
                  
                  this.essential.venue.goToPlace({ id: pointMap});

                  this.essential.content.setPlaceColor({ id: pointMap, color: 'red' });

                  //----------------- Mise en place de l'icone-------------------//
                  var pois = this.essential._mapviewer.getPOIs(pointMap);
                  console.log("POIS------", pois);
                  var position;
                  if (pois[pointMap] && pois[pointMap].length > 0) {
                    var options = pois[pointMap][0].options();
                   // floor = options.floor;
                    position = options.position;                 
                    console.log("position------>", position);
                    
                       this.essential._mapviewer.addPOI({
                         url: "/assets/images/black-marker.png",
                        //  text: 'The object "'+element.vg.id+'" has been clicked',
                          position: position,
                          alignment: {
                            "x": 0,
                            "y": 1,
                          },
                         id: 'pictoLoc',
                       // onObjectMouseUp: function() {alert('click');},
                         scale: 6
                    });
                }
                }, 1);
             
            }
          });
  
  
          this.setupNavigation();
          this.setupPlaceBubble();
          this.setupSelectors();
         // this.setupSearch();
  
        })
        .catch(e => { // eslint-disable-line
          console.log(e);
          console.log('Could not load map. Check your config or get in touch with Visioglobe team.'); // eslint-disable-line
        });
    }, 100);

  }

  goTo(id){
    if(id == 1){
      this.essential.venue.goToGlobal();
      return;
    }
    if(id == 5){
      this.essential._mapviewer.camera.minRadius = this.zoomCamera;
      this.essential.venue.goToPlace({ id: "OUTID"});
      return;
    }
    this.essential.venue.goToBuilding({ id: id });
  }

  zoom(){
   // console.log('je zoom');
     let viewpoint = this.essential.venue.getViewpoint();
     //console.log("this.essential.venue.getViewpoint()----", this.essential.venue.getViewpoint())
      viewpoint = this.essential.venue.goToViewpoint({position: viewpoint.position, radius: viewpoint.radius -= 300}); 
  }

  dezoom(){
   // console.log('je dézoom');
    let viewpoint = this.essential.venue.getViewpoint();
   // console.log("this.essential.venue.getViewpoint()----", this.essential.venue.getViewpoint())
    viewpoint = this.essential.venue.goToViewpoint({position: viewpoint.position, radius: viewpoint.radius += 300}); 
  }

  // ngOnDestroy(){
  //   this.setupPlaceBubble();
  // }

  //--------------- btn Suivant sur la modal-------------//
  nextElement(){
    console.log("nbARR---", this.arrFilterStand.length)
    if(this.elementsNext >= this.arrFilterStand.length)
    return;
    let elem = 5
    this.elementsNext += elem;
   // console.log("elemNext----", this.elementsNext);

    this.elementFirst += elem; 
  }

  //-------------- Btn précédent sur la modal
  previousElement(){
    if(this.elementFirst == 0 && this.elementsNext == 5)
    return;
    let elem = 5
    this.elementsNext -= elem;
    //console.log("elemNext----", this.elementsNext);

    this.elementFirst -= elem;
   // console.log("elemFirst---", this.elementFirst);
  }

  //-----------Redirection vers une place-------------//
  gotToMapPoint(mapPoint){
    console.log(this.essential);
    
    setTimeout(() => {
      this.essential.venue.goToPlace({ id: mapPoint});
      //this.essential.content.setPlaceColor({ id: params.id, color: 'red' });
    }, 1);
  }

  //---------------------GO to LOCATION----------------//
  goToPlace(data) {
    console.log("data:", data);
    this.isModalOpen = false;

    if (!data) return;

    let idBuilding = data.group.replace(' ', '');
    try {
      if (idBuilding.includes("OUT")) {
        setTimeout(() => {
          this.essential.venue.goToGlobal();
          data.list.forEach((element) => { this.essential.content.setPlaceColor({ id: element.MapPoint, color: 'red' }) });
        }, 1);

        return;
      }

      let pof = this.essential.venue.getPointOfFocus({ id: idBuilding });
      console.log(pof);

      setTimeout(() => {
        this.essential.venue.goToBuilding({ id: idBuilding });
        data.list.forEach((element) => { this.essential.content.setPlaceColor({ id: element.MapPoint, color: 'red' }) });
      }, 1);
    }
    catch (e) {
      console.warn("[visioglobe] error:", e.message);
      alert(idBuilding + " => POI not found !");
    }
  }

  //----------------------Progress bar---------------------// 
  setupProgress() {
    let progress = document.getElementById('loadProgress');
    // console.log("progress----", progress.value);
    this.essential.onLoadProgress = value => {
      //console.log("progress----", value);
      this.progress = value;

      if (value === 1) {
        setTimeout(() => progress.style.display = 'none', 500);
      }
    };
  }

//------------------------animation camera------------------//
startCameraAnimation() {
  this.initialCameraViewpoint();
}

//------------------------animation camera------------------//
initialCameraViewpoint() {
  let lPoints = this.essential.venue.getFootprintPoints({ id: "B5A" });
  let lViewPoint = this.essential.venue.getViewpoint({ points: lPoints });
  lViewPoint.animationDuration = 0;
  this.essential.venue.goToViewpoint(lViewPoint)
    .then(() => {
      this.firstCameraAnimation();
    })
}

//------------------------animation camera------------------//
firstCameraAnimation() {
  // Peut remplacer l'identifiant "B5A" par l'identifiant d'une autre footprint de votre choix
  let lPoints = this.essential.venue.getFootprintPoints({ id: "B5A" });
  let lViewPoint = this.essential.venue.getViewpoint({ points: lPoints });
  lViewPoint.animationDuration = 0.5;

  this.essential.venue.goToViewpoint(lViewPoint)
    .then(() => {
    })
}

setupSelectors() {
  this.venuLayout = this.essential.venue.layout;
  console.log("venuLayout----", this.venuLayout);
  if (this.venuLayout.buildings.length > 0) {
    if (this.venuLayout.hasGlobalLayer) {
      this.goToGlobal();
    }
    else {
      console.log(" document.querySelector('#global_button').style.display");
    }
    //this.buildingSelected = venuLayout.buildings[venuLayout.defaultBuildingIndex];
    //console.log("this.buildingSelected--", this.buildingSelected)
    if (this.venuLayout.buildings.length > 1) {
      let buildings = _.mapValues(this.venuLayout.buildingByID, (building, id) => this.essential.venue.getLocalizedName({ id }))
      let buildArray = Object.keys(buildings).map(id => ({ id, text: buildings[id] }))
      this.buildings = buildArray;
      //console.log("buildingsbb-->", bbb)
    }
    console.log("buildings-->", this.buildings)
    // if (this.essential.venu && this.essential.venue.layout && this.essential.venue.currentBuildingID){
    //   console.log("floors");
    //  let floors = this.essential.floorsByBuilding[this.essential.venue.currentBuildingID];
    //  console.log("floors", floors);
    // }
    // return [];
  }
}

currentExploreMode() {
  return this.essential.venue ? this.essential.venue.currentBuildingId : 'global'
}

/**
  * Navigation buildings
  * @param event
  */
onChange(event) {
  console.log("builId--", event.target.value);
  this.buildingSelected = event.target.value;
  this.essential.venue.goToBuilding({ id: this.buildingSelected })
  this.insertFloors(this.buildingSelected);
}

  /**
 * Retourne la liste des floors d'un building
 * @param buildingID
 */
   insertFloors(buildingID) {
    console.log("----", this.venuLayout.buildingByID[buildingID].floors.length)
    if (this.venuLayout.buildingByID[buildingID].floors.length >= 1) {
      console.log('*****')
      let sortedFloors = _.reverse(_.sortBy(this.venuLayout.buildingByID[buildingID].floors, 'levelIndex'));
      console.log("sortedFloors", sortedFloors);
      this.floors = _.map(sortedFloors, floor => ({
        id: floor.id,
        name: this.essential.venue.getLocalizedName({ id: floor.id })
      }));
      console.log("floorNames---", this.floors)
    }
  }

  /**
  * navigation floors
  */
  onChangeFloor(event) {
    console.log("floorSelected--", event.target.value);
    this.floorSelected = event.target.value;
    this.essential.venue.goToFloor({ id: this.floorSelected });
  }

/**
   * Retour a la carte global
   */
 goToGlobal() {
  this.essential.venue.goToGlobal();
}

setupNavigation() {
  this.prevInstructionElement = this.essential.parameters.locale.route.previous;
  this.nextInstructionElement = this.essential.parameters.locale.route.next;
  this.clearRouteElement = this.essential.parameters.locale.route.clear;
  this.updateInstructionData = () => {
    this.instructionIcon = this.essential.navigation.getCurrentInstructionIcon();
    //console.log("instructionIcon---", this.instructionIcon)
    this.instructionBrief = this.essential.navigation.getCurrentInstructionBrief();
    //console.log("instructionBrief---", this.instructionBrief)
    this.instructionDetail = this.essential.navigation.getCurrentInstructionDetail();
    //console.log("instructionDetail---", this.instructionDetail)
  }
  console.log("updateData--->", this.updateInstructionData())
  this.essential.on('navigationComputed', () => {
    this.updateInstructionData();
    document.getElementById('navigation').style.display = '';
    //console.log("prevEss---", this.essential.navigation.nbInstructions)
    //document.getElementById('prevInstruction').style.display = this.essential.navigation.nbInstructions > 1 ? '' : 'none';
    //document.getElementById('nextInstruction').style.display = this.essential.navigation.nbInstructions > 1 ? '' : 'none';
  })
}

previous() {
  this.essential.navigation.goToPreviousInstruction();
  this.updateInstructionData();
  console.log("updateData--->", this.updateInstructionData())
}

next() {
  this.essential.navigation.goToNextInstruction();
  this.updateInstructionData();
  console.log("updateData--->", this.updateInstructionData())
}

clearRoute() {
  document.getElementById('navigation').style.display = 'none';
  this.essential.route.clear();
  console.log("updateData--->", this.updateInstructionData())
}



/**
* Modal active
*/
setupPlaceBubble() {
  this.routeFromElement = this.essential.parameters.locale.route.start;
  console.log("routeFromElement--", this.routeFromElement)
  this.routeToElement = this.essential.parameters.locale.route.destination;
  console.log("routeToElement--", this.routeToElement)
  this.essential.route.setFrom({ from: this.essential.content.activePlaceID });
  this.essential.content.placeBubbleEnabled = true;

 // this.essential.onObjectMouseUp = ({ targetElement }) => this.setActivePlace(targetElement);
 this.essential.onObjectMouseUp = ({ targetElement }) => {
 

  console.log("target----", targetElement)
  this.activatedRoute.queryParams.subscribe((param) => {
    console.log('-------paramMapId--------',param.idMap);
      if(param.idMap){
       // this.essential.venue.goToPlace(targetElement);
        //this.bubble();
        this.essential.content.resetPlaceColor({id: param.idMap});
        var pois = this.essential._mapviewer.getPOIs();
        if (pois['pictoLoc'] && pois['pictoLoc'].length > 0) {
          //console.log(pois['pictoLoc'][0]);
          pois['pictoLoc'][0].remove();
          console.log(this.essential._mapviewer.getPOIs());
        //  let path = this.router.url;
        //  if(path.includes('solutions')) this.router.navigate(['home'], {queryParams:{param: 'solutions'}})
         
      } 
    }  
  });

  this.setActivePlace(targetElement)
};
}

/**
 * bouton start sur la modal
*/
routeFrom() {
  console.log("from", this.essential.content.activePlaceID)
  this.essential.route.setFrom({ from: this.essential.content.activePlaceID });
}

/**
  * bouton destination sur la modal
  */
 routeTo() {
  console.log("to", this.essential.content.activePlaceID )
  this.essential.route.setTo({ to: this.essential.content.activePlaceID });
}

/**
* fermeture de la modal
*/
 closeBubble() {
   console.log("close bubble");
   
  this.essential.content.resetActivePlace();
}

  /**
  * récupération des infos d'une place active
  * @param place
  */
   setActivePlace(place) {
    //this.essential.content.resetActivePlace();
    console.log("place----", place)
    console.log('placeActive-----',this.essential.content.activePlaceID)
    const placeContent = this.essential.content.places[this.essential.content.activePlaceID];
    console.log("placeContent---", placeContent)
    
    this.essential.content.setActivePlace({ place });
    
    requestAnimationFrame(() => {
      const placeDescription = document.getElementById('placeDescription');
      if (this.essential.content.activePlace && this.essential.content.places[this.essential.content.activePlaceID]) {
        const placeContent = this.essential.content.places[this.essential.content.activePlaceID];
        console.log("placeContent---", placeContent)
        if (placeContent) {
          console.log(placeContent.name)

          //========================== Mettre en surbrillance le stand avec l'icone localisation (a faire si on demande) ============================//

          // this.essential.content.setPlaceColor({ id: placeContent.id, color: 'red' });
          // var pois = this.essential._mapviewer.getPOIs(placeContent.id);
          // console.log("POIS------", pois);
          // var position;
          // var floor;
          // if (pois[placeContent.id] && pois[placeContent.id].length > 0) {
          //     var options = pois[placeContent.id][0].options();
          //     floor = options.floor;
          //     position = options.position;
              

          //     console.log("position------>", position);
              
          //        this.essential._mapviewer.addPOI({
          //          url: "/assets/images/black-marker.png",
          //         //  text: 'The object "'+element.vg.id+'" has been clicked',
          //           position: position,
          //           alignment: {
          //             "x": 0,
          //             "y": 1,
          //           },
          //          id: 'pictoLoc',
          //        // onObjectMouseUp: function() {alert('click');},
          //          scale: 6
          //     });
          // }


          //=====================Filter EXHI=======================//
         // console.log("this.exhibitors---", this.exhibitors)
          let arrFilterExhi = _.filter(this.exhibitors, function (item) { return item.Stands; });
          arrFilterExhi = _.filter(arrFilterExhi, (item) => {
            let results = _.filter(item.Stands, (stand) => {
              return stand.MapPoint == placeContent.id;
            })
            return results.length > 0;
          })

         // console.log("this.arrFilterExhi----------", arrFilterExhi);
          this.arrFilterStand = []
          for (let index = 0; index < arrFilterExhi.length; index++) {
            this.arrFilterStand.push({
              id: arrFilterExhi[index].Exhi_Id,
              label: arrFilterExhi[index].Exhi_CompanyName,
              type: 'exhi',
            })
          }

          //====================Filter CONF=======================//
          let arrFilterConf = _.filter(this.conferences, function (item) { return item.Place.MapPoint == placeContent.id; });

          for (let index = 0; index < arrFilterConf.length; index++) {
            this.arrFilterStand.push({
              id: arrFilterConf[index].Event_Id,
              label: arrFilterConf[index].Event_Title,
              type: 'conf',
            })
          }
        }

        this.placeTitle = placeContent.name || placeContent.id;
        if (placeContent.icon) {
          this.placeIcon = placeContent.icon
        }
        else if (!this.placeIcon && placeContent.categories.length > 0) {
          const category = this.essential.content.categories[placeContent.categories[0]];
          if (category.icon) {
            this.placeIcon = category.icon;
          }
        }
        if (placeContent.description) {
          //this.placeDesctription = placeContent.description;
          placeDescription.innerHTML = placeContent.description;
        }
      }
      //à faire après=================
      let test = this.essential.route.hasFrom();
      console.log("test---", test);
      let testTo = this.essential.route.hasTo();
      console.log("testTo---", testTo);
    })

  }


  /**
   * Search
   */
   setupSearch() {
    console.log("search---->", this.searchTerm)
    this.categoryPlaces = Object.values(this.essential.content.places);
    //console.log("categoryPlaces------>", this.categoryPlaces);
    this.categoriesTabNameElement = this.essential.parameters.locale.search.categories;
    console.log("categoriesTabNameElement------>", this.categoriesTabNameElement);
    this.placesTabNameElement = this.essential.parameters.locale.search.places;
    console.log("placesTabNameElement------>", this.placesTabNameElement);
    this.searchInput = this.essential.parameters.locale.search.placeholder;

    let filterPlacesByCategoryID = categoryID => {
      this.categoryPlaces = _.filter(this.essential.content.places, place => place.categories.indexOf(categoryID) !== -1);
      console.log("this.categoryPlacesFilter", this.categoryPlaces)
    };
  }

  searchPlace(place) {
    const categoryNames = place.categories ? _.map(place.categories, categoryID => this.essential.content.categories[categoryID].name.toLowerCase()) : [];
    console.log("categoryNames", categoryNames)
  }
  updatePlaces() {
    let places = _.filter(this.categoryPlaces, this.searchPlace)
    //this.addPlaces()
  }

  onSearche(event) {
    console.log(event.detail.value)
    this.searchTerm = event.detail.value;
  }
  closeMenu() {
    this.menu.close();
    // updateCategories();
    // this.resetCategory();
  }


 /**
   * Ovrir la barre de recherche
   */
  async openMenu() {
    await this.menu.open();
  }

   //Go to Exhibitor
   goToDetail(item) {
    //this.route.navigate(['home'], { queryParams: { param: 'detail-exhibitors', id: exhiId } });
    let route = (item.type == "exhi") ? 'detail-exhibitors' : 'detail-conference'
    this.router.navigate(['home'], { queryParams: { param: route, id: item.id } });
  }

}
